@import "../variables";

.picker-step {
  font-size: 12px;
  width: 100%;
  margin: 0 5px;
  display: none;
  flex-direction: column;
  cursor: pointer;
  &-line {
    width: 100%;
    width: 100%;
    height: 4px;
    background: #F1F6FD;
    border-radius: 4px;
    margin-bottom: 5px;
    transition: all .3s;
    display: none;
    @include breakpoint(sm) {
      display: block;
    }
  }
  
  &-title {
    display: flex;
    align-items: center;
    justify-content: center;
    @include breakpoint(sm) {
      display: block;
      justify-content: flex-start;
    }
    .ant-checkbox-wrapper {
      font-size: 18px;
      @include breakpoint(sm) {
        font-size: 14px;
      }
    }
  }
  .ant-checkbox {
    &-wrapper {
      color: #7B8CB5;
      &-checked {
        color: $color-dark;
        .ant-checkbox-inner {
          background-color: $color-primary;
          border-color: $color-primary;
        }
      }
    }
    &-inner {
      border-radius: 50%;
    }
  }
  &.finished {
    .picker-step-line {
      background: $color-primary;
    }
  }
  &.active {
    display: flex;
    .picker-step-line {
      background: #7B8CB5;
    }
    .ant-checkbox {
      &-inner {
        border-color: #7B8CB5 !important;
        background: #7B8CB5;
        &::before {
          content: "";
          display: block;
          height: 6px;
          width: 6px;
          margin: 4px 0 0 4px;
          background-color: $color-white;
          border-radius: 50%;
        }
      }
      &-wrapper {
        color: #7B8CB5;
      }
    }
  }
  @include breakpoint(sm) {
    & {
      display: flex;
    }
  }
  &-total-steps {
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: $color-primary;
    @include breakpoint(sm) {
      display: none;
    }
  }
}

.picker-steps {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @include breakpoint(sm) {
    flex-direction: row;
  }
}

.vertical-steps {
  gap: 12px;
  @include breakpoint(sm) {
    gap: 50px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @include breakpoint(md) {
    gap: 70px;
  }
  .ant-steps-item {
    flex: 1;
    &-container {
      display: flex;
      .ant-steps-item-tail {
        @include breakpoint(sm) {
          top: unset !important;
          bottom: 20px;
        }
        &::after {
          background: unset;
          border: 2px dashed $color-primary;
          height: 65px !important;
          margin-top: -5px;
          margin-left: 10px;
          @include breakpoint(sm) {
            border: none;
            border-bottom: 2px dashed $color-primary;
            height: 0 !important;
            margin-left: 30px;
          }
        }
      }
      .ant-steps-item-icon {
        width: 10px;
        height: 10px;
        @include breakpoint(sm) {
          display: none;
        }
        .ant-steps-icon-dot {
          background: $color-primary-1;
          border: 2px solid $color-primary;
          left: -2px !important;
        }
      }
      .ant-steps-item-content {
        overflow: visible;
        flex: 1;
        @include breakpoint(sm) {
          width: 100%;
        }
        .ant-steps-item-title {
          width: 100%;
          position: relative;
          padding: 0;
        }
      }
    }
  }
}
.vertical-dashed-steps {
  .ant-steps-item-container {
    .ant-steps-item-tail {
      &::after {
        height: 100% !important;
        width: 0 !important;
        border-right: 2px dashed #7B8CB5;
        background: none;
      }
    }
    .ant-steps-item-icon {
      width: 10px;
      height: 10px;
      .ant-steps-icon-dot {
        background: none !important;
        border: 2px solid $color-dark;
        left: -2px !important;
      }
    }
    .ant-steps-item-content {
      padding-bottom: 40px;
      .ant-steps-item-title {
        width: 100%;
        font-weight: 500;
        color: $color-primary;
        font-size: 12px;
        .ant-steps-item-subtitle {
          .ant-list-item-meta-title {
            font-weight: 700;
            color: $color-dark;
          }
          .ant-list-item-meta-description {
            font-size: 12px;
            .anticon {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}
.horizontal-dashed-steps {
  overflow: auto;
  .ant-steps-item-container {
    .ant-steps-item-tail {
      &::after {
        height: 0 !important;
        border-top: 2px dashed #7B8CB5;
        background: none;
        margin-left: 10px;
        margin-top: 2px;
      }
    }
    .ant-steps-item-icon {
      width: 10px;
      height: 10px;
      .ant-steps-icon-dot {
        background: none !important;
        border: 2px solid $color-dark;
        left: -2px !important;
      }
    }
  }
  .ant-steps-item-content {
    .ant-steps-item-title {
      width: 100%;
      font-weight: 500;
      color: $color-primary;
      font-size: 12px;
      .ant-steps-item-subtitle {
        .ant-list-item-meta-title {
          font-weight: 700;
          color: $color-dark;
        }
        .ant-list-item-meta-description {
          font-size: 12px;
          .anticon {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
