@import "../variables";
@import "../utils";

.ant-page-header {
  padding-left: 0;
  padding-right: 0;
  &-heading {
    &-extra{
      width: 100%;
      @include breakpoint(sm) {
        width: auto;
      }
    }
    &-title {
      color: #1A1F36;
    }
  }
}

.pic-page-header {
  .ant-avatar {
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    @include breakpoint(md) {
      height: 45px;
      width: 45px;
      margin-right: 15px;
    }
    img {
      width: 21px;
      height: 21px;
      @include breakpoint(md) {
        width: 25px;
        height: 25px;
      }
    }
  }
  .ant-page-header-heading-extra {
    width: auto;
  }
  .ant-page-header-heading-title {
    color: $color-dark;
    font-weight: bold;
    font-size: 26px;
    line-height: 32px;
    @include breakpoint(md) {
      font-size: 34px;
      line-height: 41px;
    }
    @include breakpoint(xl) {
      font-size: 38px;
      line-height: 46px;
    }
  }
}