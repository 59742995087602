.store-status {
  border-radius: 50%;
  padding: 0 3px;
  margin-right: 0;
  & > span {
    display: none;
  }
  @include breakpoint(sm) {
    border-radius: 5px;
    & > span {
      display: inline-block;
    }
  }
  @include breakpoint(md) {
    border-radius: 50%;
    padding: 0 3px;
    & > span {
      display: none;
    }
  }
  @include breakpoint(xxl) {
    border-radius: 5px;
    & > span {
      display: inline-block;
    }
  }
}