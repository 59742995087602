.chat-item {
  .ant-comment-content {
    border: 1px solid #ddd;
    padding: 5px;
    border-radius: 4px;
    flex: 0 0 auto;
  }
  .ant-comment-inner {
    padding-bottom: 0;
  }
}
.chat-item-self {
  &.ant-comment{
    display: flex;
    justify-content: flex-end;
  }
  .ant-comment-inner {
    padding-bottom: 0;
  }
  .ant-comment-content {
    background: #31a9f5;
  }
  .ant-comment-content-author {
    justify-content: flex-end;
    .ant-comment-content-author-name > a{
      color: white
    }
  }
  .ant-comment-content-author-time {
    color: #dedede;
  }
  .ant-comment-content-detail {
    display: flex;
    justify-content: flex-end;
  }
}