@import "../variables";

.badge {
  min-width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: $color-error;
  display: flex;
  align-items: center;
  justify-content: center;
}