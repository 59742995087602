.card-select {
  max-height: 370px;
  overflow: auto;
  position: relative;
  &--disabled {
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, .1);
      z-index: 3;
    }
  }
  &--item {
    cursor: pointer;
    padding: 12px 20px;
    &:hover {
      background-color: rgba(0, 0, 0, .1);
    }
    &.selected {
      background-color: rgba(0, 0, 0, .05);
    }
    &.disabled {
      cursor: not-allowed;
      background-color: rgba(0, 0, 0, .1);
      &:hover {
        background-color: rgba(0, 0, 0, .1);
      }
    }
    &--logo {
      width: 30px;
    }
    &--title {
      font-size: 14px;
      color: #3F4D70;
    }
    &-add {
      cursor: pointer;
      padding: 12px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      & > button {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
  &--no-cards {
    color: #a2a1a1 !important;
    max-width: 300px;
    text-align: center;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
  }
  &--card-button {
    width: 200px;
    margin: 40px auto 0;
  }
}