@import "../variables";

.ant-tabs-nav{
  margin-bottom: 0 !important;
  &::before {
    border-bottom: 1px solid $color-neutral !important;
  }
}

.ant-tabs-tab {
  border: 1px solid $color-neutral !important;

  &-active {
    background-color: $color-purple-1 !important;
    border: 1px solid $color-purple !important;
    border-bottom: none !important;
  }

  &-btn{
    font-weight: 500;
    color: $color-dark;
  }
  &.ant-tabs-tab-active{
    .ant-tabs-tab-btn{
      color: $color-purple;
    }
  }
}
.ant-tabs-ink-bar {
  background: $color-purple;
}