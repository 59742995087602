@import "../variables";

.ant-select-selector{
  border-radius: 4px;
}
.ant-select-selection-item{
  font-size: 14px;
}

.suggestion-select {
  .ant-select-selector {
    padding: 0 !important;
    background: $color-white !important;
    border: none !important;
    height: 38px !important;
    box-shadow: none !important;
    .ant-select-selection-search {
      left: unset;
      right: unset;
      width: 100%;
      .ant-select-selection-search-input {
        padding-right: 0 !important;
        font-size: 12px;
        line-height: 22px;
        padding: 8px 0;
        font-weight: 400;
        height: 40px;
      }
    }
    .ant-select-selection-item, .ant-select-selection-placeholder {
      padding-right: 0 !important;
      font-size: 12px;
      line-height: 22px;
      padding: 8px 0;
      font-weight: 400;
    }
  }
  .ant-select-arrow {
    display: none;
  }
  &-dropdown {
    width: 350px !important;
  }
}

.suggestion-item-favorite {
  height: 32px;
  width: 30px;
  background-color: $color-white;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-input-v2 {
  width: 100%;
  .ant-select-selector {
    padding: 0 !important;
    background: #F1F6FD !important;
    border-color: #F1F6FD !important;
    height: 40px !important;
    box-shadow: none !important;
    .ant-select-selection-search {
      left: unset;
      right: unset;
      .ant-select-selection-search-input {
        padding-right: 0 !important;
        font-size: 12px;
        line-height: 22px;
        padding: 8px 13px;
        font-weight: 400;
        height: 40px;
      }
    }
    .ant-select-selection-item, .ant-select-selection-placeholder {
      padding-right: 0 !important;
      font-size: 12px;
      line-height: 22px;
      padding: 8px 13px;
      font-weight: 400;
    }
  }
  .ant-select-arrow {
    display: none;
  }
}

.picker-select {
  &.ant-select-lg {
    .ant-select-selector {
      height: 50px !important;
      border-radius: 8px;
      .ant-select-selection-item {
        line-height: 50px !important;
      }
    }
  }
}

.signup-select {
  .ant-select-selector {
    border-color: $color-purple !important;
    background-color: #F8FBFF !important;
    color: $color-purple;
    height: 45px !important;
    border-radius: 4px !important;
  }
  .ant-select-selection-item {
    font-size: 16px !important;
    line-height: 45px !important;
  }
  .ant-select-selection-placeholder {
    font-family:  "Montserrat";
    letter-spacing: 1px;
    color: #C9D6E8;
    font-size: 16px;
    line-height: 45px !important;
  }
}

.language-selector.ant-select-open {
  .ant-select-selector {
    background-color: $color-neutral-2;
    border-color: $color-neutral !important;
  }
}

.ant-select-item-option-selected {
  background-color: $color-purple-1 !important;
}

.ant-select-item-option {
  padding-left: 12px;
}

.ant-select-item-option-state {
  display: flex;
  align-items: center;
}

.ant-select-item-group {
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  color: $color-dark-2;
  display: flex;
  align-items: center;
}

.ant-select-item-option-selected {
  .ant-select-item-option-content > div {
    color: $color-purple;
    svg path {
      fill: $color-purple !important;
    }
  }
}
