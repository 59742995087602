@import '../variables';
@import '../utils';

.subtitle {
  color: $color_dark;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 16px;
  display: block;
  text-align: center;
  width: 100%;

  @include breakpoint(md) {
    text-align: left;
  }
}

.containerForm {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
  padding: 0 8px;

  @include breakpoint(md) {
    justify-content: flex-start;
    width: 450px;
    padding: 0;
  }
}

.formInput {
  margin-bottom: 24px;

  label {
    color: $color_dark;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    &::before {
      content: none !important;
    }
  }
  input {
    border-color: $color_purple;
    background-color: #f8fbff;
    color: $color_purple;
    font-size: 16px;
    height: 45px;
    &[type="password"] {
      font-family: caption;
      font-size: 26px;
      line-height: 5px;
      letter-spacing: 4px;
    }
    &::placeholder {
      font-family: "Montserrat";
      letter-spacing: 0px;
      color: #c9d6e8;
      font-size: 16px;
    }
  }
}


.button {
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  line-height: 20px;

  @include breakpoint(md) { width: 40%; }
}


.containerPersonalSettings {
  position: relative;
  padding: 32px 8px;

  .header {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 24px;

    .icon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: $color-neutral-3;
      margin-right: 16px;
    }
  }

  .text {
    font-size: 14px;
    font-weight: 600;
    color: $color_dark;
    line-height: 18px;

    &.title {
      font-size: 32px;
      font-weight: 700;
      line-height: 46px;
    }
  }
}

.flag {
  margin-right: 5px;
  height: 10px;
  width: 15px;
}

.countrySelect {

  display: flex;
  align-items: center;

  .flag {
    margin-right: 8px;
    width: 18px;
    height: 14px;
  }

}
