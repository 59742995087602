@import "../../styles/utils";
@import "../../styles/variables";

.mainLayout {
  min-height: 100vh;
  height: auto;

  .sider {
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    border-right: solid 2px $color-neutral;
  
    transition: all 300ms cubic-bezier(0.2, 0, 0, 1) 0s;

    :global(.ant-layout-sider-children) { height: 100%; }
    
  }

  .toggle {
    position: absolute;
    top: 120px;
    left: 232px;
    z-index: 999;
    box-shadow: 0 2px 8px 0 rgba(9,30,66,0.25);
    transition: all 300ms cubic-bezier(0.2, 0, 0, 1) 0s;

    &.collapsed {
      left: 56px;
    }
  }

  .contentLayout {
    margin-left: 250px;
    width: calc(100% - 250px);
    min-height: 100vh;
    height: auto;
    
    transition: all 300ms cubic-bezier(0.2, 0, 0, 1) 0s;

    &.collapsed {
      margin-left: 72px;
      width: calc(100% - 72px);
    }
    .content {
      margin-left: 24px;
    }

    .options {
      background-color: $color-white;
      width: 100%;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 32px;
    }
  }
}