.ant-dropdown{
  &-menu-item-group-title{
    background-color: #f7fafc;
    text-align: center;
    font-weight: 400;
    size: 14px;
    color: #1a1f36;
  }
  &-menu-item{
    text-align: start;
    font-weight: 400;
    font-size: 14px;
    color: #525f7f;
  }
  .ant-menu {
    padding-top: 0;
    box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 30%);
  }
}