.ant-radio {
  .ant-radio-inner {
    width: 20px;
    height: 20px;
    background-color: #D3DFEE;
  }

  &.ant-radio-checked {
    .ant-radio-inner {
      border: none;
      background-color: #57DBFF;
      &::after {
        transform: scale(0.6);
      }
    }
  }
}

.radio-custom {
  border: none !important;
  height: auto;
  width: auto;
  padding: 0;
  box-shadow: none;
  filter: grayscale(0.9);
  &.ant-radio-button-wrapper-checked {
    transform: scale(1.2) !important;
    filter: none;
  }
  &:hover {
    transform: scale(1.1);
  }
}

.radio-box {
  .ant-radio-inner {
    border-radius: 3px;
    &::after {
      border-radius: 2px;
    }
  }
  &-big {
    &.ant-radio-wrapper {
      align-items: flex-start;
      line-height: 25px;
    }
    .ant-radio {
      margin-top: -2px;
    }
    .ant-radio-inner {
      width: 25px;
      height: 25px;
      &::after {
        top: 3px;
        left: 3px;
        width: 17px;
        height: 17px;
      }
    }
  }
}