.checkbox-v2 {
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #00B2E3;
      border-color: #00B2E3;
    }
  }
  span {
    color: #7B8CB5;
    font-weight: 500;
    font-size: 12px;
  }
}

.checkbox-big {
  align-items: flex-start;
  .ant-checkbox {
    .ant-checkbox-inner {
      width: 25px;
      height: 25px;
      &::after {
        width: 6px;
        height: 11px;
        left: 27.5%;
      }
    }
    & + span {
      margin-top: 3px;
      line-height: 25px;
    }
  }
}

.checkbox-custom {
  filter: grayscale(0.9);
  .ant-checkbox{
    display: none;
  }
  &.ant-checkbox-wrapper-checked {
    transform: scale(1.2) !important;
    filter: none;
  }
  &:hover {
    transform: scale(1.1);
  }
}