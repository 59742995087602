.ant-form-item{
  margin-bottom: 0;
  &-label {
    &.ant-col-0 {
      display: none;
    }
    &.ant-col-24 {
      label {
        color: #3F4D70;
        font-size: 12px;
        line-height: 14px;
        height: auto;
        font-weight: 500;
        &::before{
          display: none !important;
        }
      }
      padding-bottom: 4px;
    }
  }
}