.sideMenu {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 24px 0;

  .infoDelivery {
    transition: all 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
    
    &.collapsed {
      .deliveryName {
        display: none;
      }
    }

    .logo {
      display: block;
      margin: 24px auto;
      transition: all 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
    }
    
    margin-bottom: 84px;
  }


  .item { 
    margin-bottom: 16px !important; 

    &.collapsed { 
      padding-left: 22px !important; 
      width: fit-content;
    }
  }

  .divider {
    width: 80%;
    min-width: 80%;
    margin: 24px auto;
  }

}