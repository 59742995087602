@import "../../styles/variables";
@import "../../styles/utils";

.containerSwitcher {
  position: relative;
}

.languageIcon {
  position: absolute;
  left: 15px;
  top: 50%;
  z-index: 2;
  width: 15px;
  height: 15px;

  svg {
    width: 15px;
    height: 15px;
  }
  
  transform: translateY(-50%);
 
  @include breakpoint(lg) {
    width: 18px;
    height: 18px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.flagContainer {
  display: flex;
  align-items: center;
  color: $color-dark;
}

.selectLanguageContainer {
  width: 81px;

  :global(.ant-select-selector) {
    padding-left: 0 !important;
    border-radius: 5px !important;
    border-color: $color-dark !important;
  }

  :global(.ant-select-selection-item) {
    padding-left: 32px !important;
    font-size: 13px;
    @include breakpoint(lg) {
      padding-left: 38px !important;
      font-size: 16px;
    }
  }

  @include breakpoint(lg) {
    width: 95px;
  }
}

.light {
  color: $color-white !important;

  div:first-of-type {
    background-color: transparent !important;
    border-color:#d9d9d9 !important;

    &:hover {
      a { color: $color-white !important; }
    }

    & + span { color: $color-white; }
  }
}
