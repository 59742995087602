.delivery-selection {
  max-width: 1000px;
  margin: 0 auto;

  &--container {
    display: grid;
    gap: 10px;
  }
  &--title {
    text-align: center;
    margin-bottom: 20px;
  }
}

.delivery-card {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #D6D6D6;
  padding: 20px 24px;
  position: relative;
  overflow: hidden;
  &.delivery-active {
    border: 1px solid #00B2E3;
  }
  .ant-list-item-meta {
      .ant-list-item-meta-title {
        font-weight: 600;
        color: #1A1F36;
        font-size: 18px;
        margin: 0;
    }
  }
  &.delivery-unavailable {
    position: relative;
    &::after {
      border-radius: 10px;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba($color: #000000, $alpha: 0.15);
    }
  }
  .pending {
    background-color: #fa8c16;
  }
  &__upgrade {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: gold;
    padding: 1px 20px;
    font-size: 11px;
    border-radius: 4px 0 0;
    letter-spacing: .5px;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.additional-card {
  margin-top: 20px;
  border: 2px solid #C3DAFF;
  background-color: rgba($color: #1790FA, $alpha: 0.07);
  border-radius: 10px;
  display: flex;
  &--amount {
    background-color: #C3DAFF;
    color: #3883FF;
    font-size: 29px;
    padding: 18px 25px;
    font-weight: 700;
  }
  &--info {
    display: flex;
    align-items: center;
    color: #3883FF;
    font-weight: 600;
    font-size: 14px;
    padding: 15px;
  }
}

.delivery-stats {
  &--title {
    color: #00B2E3 !important;
    margin: 0 !important;
    text-transform: uppercase;
    font-size: 15px !important;
    @include breakpoint(md) {
      font-size: 20px !important;
    }
  }
  &--label, &--value {
    color: #3F4D70 !important;
    font-weight: 600;
    font-size: 13px;
    margin: 0 !important;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 8px;
    @include breakpoint(md) {
      font-size: 16px;
    }
  }
  &--value {
    justify-content: flex-end;
  }
  &--progress {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2px;
    grid-column: auto / span 2;
    @include breakpoint(md) {
      grid-column: unset;
    }
  }
  &--percent {
    color: #00B2E3 !important;
    margin: 0 !important;
    display: none;
    &.danger {
      color: #ffc10b !important;
      &::before {
        content: "+"
      }
    }
    @include breakpoint(md) {
      display: block;
    }
  }
  &--container {
    display: grid;
    align-items: flex-end;
    gap: 0 20px;
    grid-template-columns: 1fr 1fr;
    & > div {
      &:nth-child(1) {
        order: 1;
      }
      &:nth-child(2) {
        order: 3;
      }
      &:nth-child(3) {
        order: 2;
      }
      &:nth-child(4) {
        order: 4;
      }
      &:nth-child(5) {
        order: 6;
      }
      &:nth-child(6) {
        order: 5;
      }

      &:nth-child(7) {
        order: 7;
      }
      &:nth-child(8) {
        order: 9;
      }
      &:nth-child(9) {
        order: 8;
      }
      @include breakpoint(md) {
        order: unset !important;
      }
    }
    @include breakpoint(md) {
      grid-template-columns: auto 1fr auto;
    }
  }
  &--map {
    min-height: 150px;
    position: relative;
    display: grid;
    @include breakpoint(md) {
      min-height: 300px;
    }
  }
}