.loader {
  position: absolute;
  height: 100vh;
  left: 0;
  right: 0;
  margin: 0;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: #fff;
}