@import "../variables";

.ant-list-item-meta-content {
  width: fit-content !important;
}

.mp-table-card {
  .ant-table-thead {

    th {
      background: $color-purple-1;
      border-bottom: solid 1.5px $color-purple !important;
      border-top: solid 1.5px $color-purple !important;
      padding: 16px;
      text-transform: uppercase;
      text-align: center;

      &:last-child {
        border-right: solid 1.5px $color-purple !important;
      }
      
      &:first-child {
        border-left: solid 1.5px $color-purple !important;
      }
      
      & > span > div {
        display: flex;
        align-items: center;
        .ant-table-column-title {
          color: $color-dark-3;
          font-size: 16px;
          font-weight: 800;
          line-height: 20px;
        }
      }
    }
  }
  .ant-table-container {

    &::before {
      box-shadow: none !important;
    }

    table {
      border-spacing: 0px 8px;
      border-collapse: separate !important;
    }

    .driver-mobile {
      background: $color-neutral-3;
      padding: 6px 8px;
      margin-top: 4px;
      border-radius: 4px; 
    }
  
    .ant-table-tbody {
      margin-top: -10px;
      .tr-new {
        td:first-child {
          border-left: 7px solid $color-purple !important;
          border-radius: 4px 0 0 4px;
          width: 230px;
        }
      }

      tr.ant-table-row {
        cursor: pointer;
        border: 0 !important;
        border-radius: 5px;
        box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.08);
        margin-bottom: 10px;
        position: relative;

        .table-store {
          font-size: 14px;
        }
        .table-total {
          font-weight: 500;
          font-size: 16px;
        }
        td {
          border: 0 !important;
          padding: 8px 16px;

          .order {
            width: 200px;
            &.ant-list-item-meta {
              align-items: center !important;
            }

            .booking-type {
              padding: 0 8px;
              margin-bottom: 4px;
        
              img { object-fit: contain; }
            }
  
            .date {
              font-size: 12px;
              color: $color-dark-2;
            }
          }
        
          .table-time {
            border: 0;
            background:linear-gradient(90deg,$color-primary,#3FCAF5);
            color:white;
            font-size: 18px;
            font-weight: 500;
            border-radius: 8px;
            padding: 8px 4px;
            margin: 0 auto;
            width: 120px;
            
            &.neutral {
              background: linear-gradient(90deg, #ffaa00, #ffdf5a);
            }
            &.warning {
              background: linear-gradient(90deg, #ff7500, #ffaa00);
            }
            &.dangerous {
              background: linear-gradient(90deg, #d90101, #ff5800);
            }
          }

          .driver {
            width: 220px;
            
            .unassigned {
              width: auto;
              border-radius: 3px;
              background-color: $color-neutral;
              color: $color-dark;
              padding: 5px 10px;
              letter-spacing: 1px;
            }
          }

          .table-status {
            border: 0;
            font-size: 12px;
            font-weight: 500;
            border-radius: 4px;
            padding: 11px 15px;
            display: inline;
            white-space: nowrap;
            color: $color-dark-3;
            margin: 0 auto;
            text-align: center;
            display: block;
            width: fit-content;

            &.READY_FOR_PICKUP, &.DISCONNECTED {
              background-color: $color-neutral;
            }
            &.ACCEPTED {
              background-color: $color-primary-1;
            }
            &.ARRIVED_AT_PICKUP, &.BUSY {
              background-color: $color-yellow-1;
            }
            &.WAY_TO_DELIVER {
              background-color: $color-blue-1;
            }
            &.CANCELLED_BY_ADMIN, &.CANCELLED_BY_DRIVER, &.CANCELLED_BY_BUSINESS, &.NOT_DELIVERED {
              background-color: $color-error-1;
            }
            &.ARRIVED_AT_DELIVERY, &.COMPLETED, &.ONLINE {
              background-color: $color-green-1;
            }
          }

          .business {
            width: 150px;
          }

          .table-billedDistance {
            width: 210px;
          }
        }
      }
    }
  }
}

.driver-table {
  td {
    text-align: center;
    button {
      display: inline-flex;
    }
  }
}