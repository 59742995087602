.ant-input {
	border-radius: 4px;
	padding: 12px 20px;
  border: solid 1px $color-purple;
  background-color: $color-neutral-3 !important;

  font-weight: 400;
  font-size: 14px;

	&[type="number"]::-webkit-outer-spin-button,
	&[type="number"]::-webkit-inner-spin-button,
	&[type="number"] {
		-webkit-appearance: none;
		margin: 0;
		-moz-appearance: textfield;
	}

  &[type="password"] {
    font-family: caption;
    font-size: 16px;
    line-height: 5px;
    letter-spacing: 3px;
  }

  &::placeholder {
    font-family: "Montserrat";
    color: $color-neutral;
    letter-spacing: 0px;
    font-size: 14px;
  
  }
}

.ant-form-item-label, .ant-form-item-label label {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: $color-purple;

  &::before { display: none !important; }
}

.ant-input-affix-wrapper {
	padding: 12px 20px;
	background-color: $color-neutral-3 !important;
	border: solid 1px $color-purple;
	border-radius: 4px;

  .ant-input-password-icon { 
    color: $color-purple; 
    font-size: 16px;

    &:hover { color: $color-purple; }
  }

	&.input-v2 {
		padding: 8px 13px;
		border-color: #f1f6fd;
	}
}


.form-item-v3 {
	.phone-input--country-code {
		.ant-select-selector {
			height: 45px !important;
			background-color: $color-neutral-3;
			border-color: $color-neutral;
		}
	}
	.phone-input--mobile {
		@extend .input-v3;
		height: 45px !important;
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
	}
}

.input-v3 {
	width: 100%;
	background: $color-neutral-3 !important;
	border-color: $color-neutral !important;
	font-size: 16px;
	line-height: 20px;
	padding: 12px 20px;
	font-weight: 400;
	height: 45px;
	border-radius: 5px;
	&::placeholder {
		color: lighten($color-dark, 30% );
	}
	input {
		background: $color-neutral-3 !important;
	}
}

.input-address {
	border: 1px solid $color-neutral;
	border-radius: 5px;
	color: $color-dark;
	font-weight: bold;
	font-size: 16px;
	background: white;
	&::placeholder {
		color: $color-dark;
		font-weight: bold;
		font-size: 16px;
	}
	&-delivery-box {
		border: 0;
		padding: 0;
		box-shadow: none;
		border-radius: 0;
		border-bottom: 1px solid $color-neutral;
	}
	input {
		color: $color-dark;
		font-weight: bold;
		font-size: 16px;
		line-height: 25px;
		padding: 8px 0 !important;
	}
}


.input-delivery-address {
	position: relative;
	background: white;
	border: 1px solid $color-neutral;
	border-radius: 5px;
	color: $color-dark;
	font-weight: bold;
	font-size: 16px;
	height: 43px;
	text-align: left;
	display: flex;
	align-items: center;
	padding: 0 10px;
	cursor: text;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.filters {
	&__col {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
	}
	&__input {
		&--search {
			padding: 4px 16px;
			height: 32px;
	
			&.numeric-id {
				width: 300px;
			}
		}
	}
}