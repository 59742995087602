@import '../../styles/variables';

.flag {
  margin-right: 4px;
  width: 16px;
  height: 12px;
}

.select {
  min-width: 90px;

  & > div {
    border-radius: 4px !important;
    border-color: 4color-purple !important;
    height: 40px !important;

    input {
      height: 40px !important;
    }
    span {
      div {
        font-size: 16px;
        font-weight: 400;
        line-height: 40px;
        display: flex;
        align-items: center;
      }
    }
  }
}

.option {
  padding: 8px;
  color: #3C4257;
  display: flex;
  align-items: center;
  font-size: 14px;
}