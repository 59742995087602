@import '@/styles/variables';


.logoContainer {

  cursor: pointer;
  padding: 16px;
  border-radius: 20%;

  .checkboxLogo {
    margin-top: 8px;
    -webkit-appearance: none;
    appearance: none;
    font: inherit;
    width: 1.45em;
    height: 1.45em;
    border-radius: 0.5em;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;

    &.blue {
      border: 0.15em solid $color-blue;
    }

    &.purple {
      border: 0.15em solid $color-purple;
    }

    &.yellow {
      border: 0.15em solid $color-yellow;
    }

  }

  .checkboxLogo::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    /* Windows High Contrast Mode */
  }

  .checkboxLogo:checked::before {
    transform: scale(1);
  }

  .bluebf::before {
    background-color: $color-blue;
  }

  .purplebf::before {
    background-color: $color-purple;
  }
  .yellowbf::before {
    background-color: $color-yellow;
  }
}