@import "../variables";

.notifications-container {
  grid-area: video;
  text-align: center;
  position: relative;
  background-color: $color-neutral-3;
  padding: 20px 0;
  @include breakpoint(lg) {
    border-radius: 12px;
  }
  &--image {
    position: absolute;
    top: 30px;
    height: 480px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
  }
}

.notifications {
  &--question-title {
    grid-area: question;
    align-items: center;
    color: $color-neutral-2;
    display: flex;
    font-size: 15px;
    font-weight: 600;
    gap: 5px;
    justify-content: center;
    line-height: 18px;

    @include breakpoint(lg) {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 60px;
    }
    @include breakpoint(xl) {
      justify-content: flex-end;
      margin-bottom: 0;
    }
  }
}
