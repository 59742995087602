@import './typography';
@import './aligns';
@import './colors';
@import './variables';

@import './atoms';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400;500;600;700&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat';
}

* { box-sizing: border-box; }

.fullWidth { width: 100% !important; }
.fullHeight { height: 100% !important; }

.noMargin { margin: 0 !important; }
.noPadding { padding: 0 !important; }
.noBorder { border: none !important; }

.transition { transition: all 300ms cubic-bezier(0.2, 0, 0, 1) 0s; }

.iconHeader {
  padding: 8px;
  border-radius: 4px;
  background: $color-purple-1;
  color: $color-purple;
  margin-right: 16px;
}

.iconBack {
  margin-right: 8px;
  cursor: pointer;
}

/* HOME LAYOUTS */

.contentLayouts {
  padding: 56px 32px;
}
