@import '../../styles/variables';
@import '../../styles/utils';

.itemDriver {
  margin-bottom: 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 8px 16px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  height: auto;

  &.selected {
    background-color: $color-neutral-2;
  }

  .avatarUser {
    width: 48px;
    height: 48px;
    position: relative;

    .avatar {
      width: 100%;
      height: 100%;
    }

    .dot {
      border-radius: 50%;
      position: absolute;
      bottom: -4px;
      right: -4px;
      width: 18px;
      height: 18px;
      z-index: 99;

      &.warning {
        background-color: $color-yellow;
      }

      &.success {
        background-color: $color-green;
      }
    }
  }

  .content { border: none; }

  .containerActions {
    .button {
      margin-right: 8px;
      &:hover, &:active {
        background-color: $color-neutral-3 !important;
        border-color: $color-neutral-3 !important;
      }
    }
  }

  .more {
    width: fit-content;
    cursor: pointer;

    &:active { transform: scale(0.96); }

    .icon {
      margin-right: 4px;
    }

  }

  .moreInfo {
    width: 100%;
    display: none;
    padding: 8px 0;
    transition: all 300ms cubic-bezier(0.2, 0, 0, 1) 0s;

    &.active { 
      display: block;
      transition: all 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
    }
    
    .header {
      background: $color-blue-1;
      color: $color-dark-3;
      padding: 8px 12px;
      border-radius: 4px;
    }
  }
  

}