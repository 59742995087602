@import '../utils';
@import '../variables';

@each $key, $value in $breakpoints {
  @include breakpoint($key) {
    .ant-col-#{$key}-auto {
      display: block;
      flex: unset;
    }
  }
}