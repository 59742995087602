@import '../../styles/variables';
@import '../../styles/utils';

.driverCard {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  background-color: $color-white;
  border-radius: 8px;
  padding: 48px 16px;
  position: relative;
  width: 100%;
  display: block;

  .header {
    position: absolute;
    top: -32px;
    left: -32px;
    display: flex;
    height: 64px;
    align-items: center;
    justify-content: center;

    .avatar {
      background-color: $color-purple-1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .title {
      padding: 8px 16px;
      border-radius: 0 8px 8px 0;
      background-color: $color-purple;
      margin-left: -8px;
    }
  }
}