@import "../variables";

.phone-input {
  &--country-code {
    & > div span div {
      font-size: 14px !important;
  }
    .ant-select-selector {
      border-right: 0 !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
  &--mobile {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.signup-phone {
  background-color: $color-neutral-3 !important;
  border-color: $color-purple !important;
  border-radius: 4px !important;

  display: flex;
  min-height: 47px;

  .ant-select .ant-select-selector {
    background-color: $color-neutral-3 !important;
    height: 47px !important;
    border: solid 1px $color-purple;

    .ant-select-selection-item {
      color: $color-purple !important;
      div {
        height: 47px;
      }
    }
  }
}