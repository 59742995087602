.ant-progress-bg{
  background-color: $color-primary;
}

@keyframes fill{
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

.pic-circular-progressbar {
  width: 35px;
  height: 35px;
  background: $color-dark-3;
  border-radius: 50%;
  &--circle {
    position: relative;
    &--mask, .fill {
      width: 35px;
      height: 35px;
      position: absolute;
      border-radius: 50%;
    }
    &--mask {
      clip: rect(0px, 35px, 35px, 17px);
      .fill {
        clip: rect(0px, 17px, 35px, 0px);
        background-color: $color-primary;
        &.unlimited {
          background-color: $color-yellow;
        }
      }
    }
    &--mask.full, .fill {
      animation: transform ease-in-out 3s;
      transform: rotate(0deg);
    }
    &--inside {
      width: 27px;
      height: 27px;
      border-radius: 50%;
      background: $color-dark-3;
      text-align: center;
      top: 4px;
      left: 4px;
      color: #1e51dc;
      position: absolute;
      z-index: 100;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
