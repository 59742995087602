
.pic-stepper {
  overflow-x: auto;
  border: 1px solid $color-neutral-1;
  border-left: 0;
  border-right: 0;
  padding: 15px;
  & > div {
    text-align: center;
  }
}

.pic-stepper__container {
  display: inline-flex;
  gap: 30px;
  margin: 0 auto;
}

.pic-step {
  white-space: nowrap;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  color: $color-dark;
  display: flex;
}

.pic-step__number {
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  border-radius: 50%;
  border: 2px solid $color-dark;
  color: $color-dark;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.pic-step--finished {
  color: $color-primary;
  border-color: $color-primary;
  .pic-step__number {
    color: $color-primary;
  }
}
.pic-step--active {
  color: $color-primary;
}

.pic-step__icon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}