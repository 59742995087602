.ant-carousel {
  .slick-dots {
    li, li.slick-active {
      height: 8px;
      width: 40px;
      button {
        height: 8px;
        width: 40px;
        border-radius: 7px;
      }
    }
  }
}