.modal-v2 {
  .ant-modal-content {
    border-radius: 4px;
    .ant-modal-header {
      border-bottom: 0;
      border-radius: 4px 4px 0 0;
      .ant-modal-title {
        text-align: center;
        margin-top: 30px;
        font-size: 18px;
        color: #3F4D70;
      }
    }
    .ant-modal-body {
      padding-top: 0;
    }
    .ant-modal-footer {
      border-top: 0;
      text-align: center;
    }
  }
}