@import '../../styles/variables';
@import '../../styles/utils';

.containerList {
  min-height: 200px;
  max-height: 500px;
  overflow-y: scroll;
  width: 100%;
  gap: 25px;
}

.containerActions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

:global(.ant-modal-confirm-btns) {
  display: flex;
}

:global(.ant-btn-dangerous) {
  background: $color-error !important;
  border: solid 1px $color-error !important;

  &:hover {
    background: $color-error;
    border: solid 1px $color-error;
  }

  span { color: $color-white !important; }
}

:global(.ant-btn-default) {
  border: solid 1px $color-dark;
  &:hover { border: solid 1px $color-dark; }
  span { color: $color-dark; }
}