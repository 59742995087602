@import '../variables';
@import '../utils';

.pageLayout {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  @include breakpoint(lg) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  &.reset {
    grid-template-columns: 1fr !important;
    padding: 0 24px;
    
    @include breakpoint(lg) { padding: 0; }
  }
}

.onboarding {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 100vh;

  padding: 16px 40px;

  @include breakpoint(md) {
    padding: 16px 75px;
  }

  @include breakpoint(lg) {
    padding: 16px 140px;
  }
}

.left {
  height: 100vh;
  width: 100%;
  padding: 16px;
  overflow-y: auto;
}

.containerLeft {
  max-width: 510px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.right {
  display: none;
  padding: 110px 48px;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;

  @include breakpoint(lg) { display: flex; } 

  .containerImages {
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(2px);
    width: 60%;
    height: 75%;
    border-radius: 4px;
  }

}

.containerHeader {
  justify-content: space-between;

  .logo {
    max-width: 160px;

    @include breakpoint(lg) { max-width: 185px; }
  }
}

.forgotPassword {
  color: $color-dark-2;
  margin: 48px 0;
  flex-wrap: wrap;
  
  svg {
    margin-right: 6px;
  }

  a {
    margin-left: 5px;
    color: $color-dark-2;
    text-decoration: underline;
  }
}

.divider {
  margin-top: 48px !important;
  border-top-color: $color-neutral !important;

  span { 
    font-style: italic; 
    width: max-content;
  }
}

.socialMedia { 
  margin-top: 60px; 

  .button {
    align-items: center;
    color: $color-neutral;
    display: flex !important;
    font-size: 16px;
    font-weight: 500;
    height: 45px !important;
    justify-content: center;
    border-radius: 5px !important;
    border: solid 1px #c9d6e8;
    padding: 12px 20px !important;
    width: 45px !important;

    img {
      width: 32px;
      height: 32px;
    }
  }
}

.footer {
  color: $color-neutral;
  font-weight: 500;
}

.containerSteps {

  .stepper {
    display: flex;
    justify-content: center;
    align-items: center;

    .step {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      .icon {
        background-color: $color-neutral;
        color: $color-white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 42px;
        height: auto;
        padding: 10px;

        svg {
          width: 100%;
          height: 90%;
          vertical-align: middle;
        }
      }

      .line {
        background-color: $color-neutral;
        color: $color-white;
        height: 2px;
        border-radius: 4px;
        width: 100%;

        &.last { width: 0; }
      }

      &.last { width: fit-content; }

      &.active {
        .icon { background-color: $color-purple; }
        .line { background-color: $color-purple; }
      }

    }
  }
}

.containerForm {
  width: 100%;
  position: relative;

  .form {
    width: 100%;
    height: 320px;
    overflow-y: scroll;
  }
  
  .button { 
    width: 95%;
    display: flex;
    margin: 24px auto 0 auto; 

    @include breakpoint(md) {
      width: 75%;
    }

    @include breakpoint(lg) {
      width: 50%;
    }
  }
}

.innerSection {
  height: auto;
  width: 90%;
  margin: 0 auto;

  @include breakpoint(lg) {
    width: 90%;
  }

  @include breakpoint(xl) {
    width: 70%;
  }

  .header {
    background: linear-gradient(90deg, #F2F8FF 0%, rgba(242, 248, 255, 0) 138.03%);
    border-radius: 4px;
    padding: 4px 24px;
    width: fit-content;
    display: block;
    margin: 24px auto;
  }

  .avatar {
    margin: 0 auto;
    display: block;
    img {
      object-fit: contain;
    }
  }
}

:global(.ant-upload.ant-upload-drag) {
  background: $color-neutral-3 !important;
  border: 3px dashed $color-purple;

}

.containerHelp {
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}


.countrySelect {

  display: flex;
  align-items: center;

  .flag {
    margin-right: 8px;
    width: 18px;
    height: 14px;
  }

}

.containerPhoneNumber {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  .label {
    color: $color-purple;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px;
  }

  .containerFields {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
  
  .containerCountryCode {
    margin-right: 8px;

    .countryCode {
        height: 50px;
        min-width: 120px;
        
        :global(.ant-select-selector) {
            background-color: $color-neutral-3 !important;
            border: solid 1.4px $color-purple;
            color: $color-dark-3;
            height: 100%;

            :global(input) {
              height: 100%;
              font-size: 16px;
              font-weight: 500;
              text-align: center;
            }

            :global(.ant-select-selection-item) {
                display: flex;
                align-items: center;
                justify-content: center;

                div {
                    font-size: 16px !important;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
        }
    }
  }

  .containerMobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    
    .mobile {
      width: 100%;
        .phone {
            padding: 5px 13px;
            background-color: $color-neutral-3 !important;
            border-color: $color-purple;
            color: $color-dark;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            height: 50px;
    
            &:focus { box-shadow: none; }
    
            &::placeholder {
                letter-spacing: 0px;
                color: $color-neutral;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
            }    

            &.error {
                border: solid 1.4px $color-error;
            }
        }

      }
  }


  .containerErrors {
    .error {
        font-size: 14px;
        color: $color-error;
        margin-bottom: 2px;
        display: block;
        width: 100%;

        &:last-child { margin-bottom: 0; }
    }
  }
}