@import "../variables";
@import "../utils";

.avatar {
  height: 30px;
  width: 30px;
  min-width: 30px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    object-fit: contain;
  }

  &--sm {
    height: 33px;
    width: 33px;
  }
  &--md {
    height: 35px;
    width: 35px;
  }
  &--lg {
    height: 45px;
    width: 45px;
  }
  &--primary {
    background-color: $color-primary;
  }
}