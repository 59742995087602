@import '../variables';

.ant-btn {
  height: 32px;
  font-weight: 500;
  font-size: 15px !important;
  letter-spacing: -0.01em;
  cursor: pointer;
  border: solid 1.4px $color-purple;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span { 
    color: $color-white;
  }

  &:active {
    transform: scale(0.96);
  }

  &.ant-btn.ant-btn-loading {
    background-color: $color-purple !important;
    flex-direction: row !important;
  }

  &.ant-btn-primary {
    color: $color-white;

    &:hover {
      border-color: #6c8eefcf;
      background: #6c8eefcf;
    }

    &[disabled] {
      &, &:hover, &:focus, &:active {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
        border-color: #d9d9d9;
        text-shadow: none;
        box-shadow: none;
      } 
    }
  }

  &.ant-btn-ghost {
    span { color: $color-purple; }

    &:hover {
      border-color: #6c8eefcf;
    }
  }

  &.sm {
    height: 24px;
    padding: 0px 8px;

    span { font-size: 12.5px !important; }
  }

  &.md {
    height: 32px;
    padding: 0px 16px;
    span { font-size: 13.5px !important; }
  }
  
  &.lg {
    width: 100%;
  }
  
  &-round {
    border-radius: 20px;
  }

  &.ant-btn-circle {
    min-width: 32px;
    padding-right: 0;
    padding-left: 0;
    border-radius: 50%;
    height: auto;
    min-height: 32px;
  }

}