.pic-navigation-bar {
  padding: 20px 15px;
  .ant-tabs-nav {
    margin: 0;
    &::before {
      bottom: unset;
      top: 0;
      border-color: #E1E8F1;
    }
    .ant-tabs-tab {
      padding-bottom: 0;
    }
    .ant-tabs-tab-btn {
      color: $color-dark;
      font-weight: 600;
    }
    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: $color-primary;
      }
    }
  }
}