@import '../../styles/variables';
@import '../../styles/utils';

:global(.ant-modal-content) {
  border-radius: 16px;
  box-shadow: 0px 5px 12px rgba(105, 105, 105, 0.25);
  padding: 0 !important;
}

:global(.ant-modal-header) {
  border-radius: 16px;
  padding: 0 !important;
}

.bodyModal {
  border-radius: 16px;
  padding: 32px;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}