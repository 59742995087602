@import "../variables";

.info-card {
  margin: 0 auto;
  border: 1px solid $color-primary;
  border-radius: 4px;
  &__title {
    background: #00b2e31a;
    font-size: 14px;
    text-align: center;
    padding: 15px;
    color: #7b8cb5;
    font-weight: 500;
    line-height: 1;
  }
  &__body {
    padding: 15px;
    color: #3c4257;
    font-size: 13px;
    text-align: center;
  }
}


.pic--option-card {
  border: 2px solid $color-neutral-2;
  border-radius: 5px;
  background: $color-white;
  padding: 14px;
  height: 180px;
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 180px;
  cursor: pointer;
  @include breakpoint(md) {
    height: 210px;
  }
  @include breakpoint(xl) {
    flex-direction: row;
    height: 80px;
    width: auto;
    max-width: 360px;
    flex: unset;
    padding: 13px;
    gap: 15px;
  }
  &__active {
    border-color: $color-green;
    background: #F5FFFA;
  }
  &--content {
    text-align: center;
    @include breakpoint(xl) {
      text-align: left;
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 6px;
    }
  }
  &--image {
    border-radius: 6px;
    width: 45px;
    height: 45px;
  }
  &--title {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: $color-dark;
    margin-top: 10px;
    @include breakpoint(md) {
      font-size: 16px;
      line-height: 20px;
    }
    @include breakpoint(xl) {
      margin-top: 0;
    }
  }
  &--subtitle {
      font-size: 11px;
      line-height: 13px;
      color: $color-dark-2;
      margin-bottom: 15px;
      @include breakpoint(md) {
        font-size: 13px;
        line-height: 16px;
      }
      @include breakpoint(xl) {
        margin: 0;
      }
  }
  &--edit {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: $color-dark;
    cursor: pointer;
    padding-left: 7px;
    @include breakpoint(md) {
      font-size: 13px;
      line-height: 16px;
      padding-left: 0px;
    }
  }
}


