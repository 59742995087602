@import '../variables';
@import '../utils';

.navigator {
  padding: 45px 24px;
  padding-top: 0;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @include breakpoint(md) {
    padding: 45px 64px;
  }
}

.renderSteps {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .steps {
    display: flex;
    overflow-x: auto;
    width: 100%;
    padding-bottom: 10px;
    margin-top: 32px;
    margin-bottom: 32px;
    justify-content: center;
    align-items: center;
    position: relative;

    &.start {
      justify-content: flex-start;

      @include breakpoint(sm) {
        justify-content: center;
      }
    }

    &.center {
      justify-content: center;
    }

    &.end {
      justify-content: flex-end;

      @include breakpoint(sm) {
        justify-content: center;
      }
    }

    .step {
      white-space: nowrap;
      margin-right: 24px;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      color: $color-dark;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      &.active{
        border-color: $color-purple;
        
        span {
          color: $color-purple;
        }
      }

      &.hide {
        display: none;
      }

      .complete {
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }

      .number {
        margin-right: 8px;
        border-radius: 50%;
        padding: 4px 8px;
        border: solid 2px $color-dark;

        &.active {
          color: $color-purple;
          border-color: $color-purple;
        }
      }

      .icon {
        margin-left: 8px;
        
        &.active {
          color: $color-purple;
        }
      }
    }
  }
}

.renderForms {
  margin: 0 auto;
  width: 100%;
  min-height: 500px;
  padding-bottom: 64px;

  @include breakpoint(md) {
    width: 800px;
  }

  .flag {
    display: flex;
    align-items: center;
    color: $color_dark;

    .icon {
      margin-right: 8px;
    }
  }
}

.containerPhoneNumber {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  .label {
    color: $color-purple;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px;
  }

  .containerFields {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
  
  .containerCountryCode {
    margin-right: 8px;

    .countryCode {
        height: 50px;
        min-width: 120px;
        
        :global(.ant-select-selector) {
            background-color: $color-neutral-3 !important;
            border: solid 1.4px $color-purple;
            color: $color-dark-3;
            height: 100%;

            :global(input) {
              height: 100%;
              font-size: 16px;
              font-weight: 500;
              text-align: center;
            }

            :global(.ant-select-selection-item) {
                display: flex;
                align-items: center;
                justify-content: center;

                div {
                    font-size: 16px !important;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
        }
    }
  }

  .containerMobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    
    .mobile {
      width: 100%;
        .phone {
            padding: 5px 13px;
            background-color: $color-neutral-3 !important;
            border-color: $color-purple;
            color: $color-dark;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            height: 50px;
    
            &:focus { box-shadow: none; }
    
            &::placeholder {
                letter-spacing: 0px;
                color: $color-neutral;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
            }    

            &.error {
                border: solid 1.4px $color-error;
            }
        }

      }
  }


  .containerErrors {
    .error {
        font-size: 14px;
        color: $color-error;
        margin-bottom: 2px;
        display: block;
        width: 100%;

        &:last-child { margin-bottom: 0; }
    }
  }
}