@import "./variables";
@import "./utils";

span {
  color: $color-dark-3;
  display: block;
  font-family: 'Montserrat';
  font-size: 14px;
  margin: 0;
  white-space: pre-line;
}

.title {
  &--hero {
    font-size: 45px;
    font-weight: 700;
    line-height: 56px;
  }

  &--header-1 {
    font-size: 26px;
    font-weight: 700;
    line-height: 32px;
  }

  &--header-2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }

  @include breakpoint(md) {
    &--hero {
      font-size: 55px;
      font-weight: 700;
      line-height: 70px;
    }
  
    &--header-1 {
      font-size: 34px;
      font-weight: 700;
      line-height: 42px;
    }
  
    &--header-2 {
      font-size: 22px;
      font-weight: 700;
      line-height: 26px;
    }
  }

  @include breakpoint(lg) {
    &--hero {
      font-size: 65px;
      font-weight: 700;
      line-height: 70px;
    }
  
    &--header-1 {
      font-size: 38px;
      font-weight: 700;
      line-height: 46px;
    }
  
    &--header-2 {
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
    }
  }
}

.paragraph {
  &--1 {
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
  }
  &--2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
  }
  &--3 {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }

  @include breakpoint(md) {
    &--1 {
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
    }
    &--2 {
      font-size: 18px;
      font-weight: 400;
      line-height: 20px;
    }
    &--3 {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
    }
  }
}

/* text transforms */

.uppercase { text-transform: uppercase !important; }
.lowercase { text-transform: lowercase !important; }

/* text alignments */

.center   { text-align: center  !important; }
.left     { text-align: left    !important; }
.justify  { text-align: justify !important; }
.right    { text-align: right   !important; }

/* type text */

.bold { font-weight: 700; }
.medium { font-weight: 500; }
.light { font-weight: 300; }