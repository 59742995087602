@import '@/styles/variables';

.emptyImage {
    width: 100%;
    height: 101px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: $color-dark; 
}