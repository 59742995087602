@import "../variables";

.pic-info {
  width: calc(100vw - 48px);
  margin-bottom: 40px;
  .ant-notification-notice-with-icon {
    display: flex;
    align-items: center;
    .ant-notification-notice-message {
      display: none;
    }
    .ant-notification-notice-description {
      margin-left: 0;
      flex: 1;
    }
    .ant-notification-notice-btn {
      margin: 0;
    }
  }
  .ant-notification-notice-close {
    top: 10px;
    right: 10px;
  }
  &--alert {
    background-color: $color-error;
    color: $color-white;
  }
  &--info {
    background-color: $color-purple;
    color: $color-white;
  }
  &--button {
    margin-right: 20px;
    text-decoration: underline;
    cursor: pointer;
  }
}