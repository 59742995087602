@import '../../styles/variables.scss';
@import '../../styles/utils.scss';

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;

  :global(.ant-card-body) { padding: 0 !important; }
}

:global(.ant-avatar) { img { object-fit: contain; } }

.cardHeader {
  display: flex;
  justify-content: space-between;
}

.data {
  display: flex;
  align-items: center;
  
  &.numericId { margin: 0 auto; }

  .icon { 
    cursor: pointer; 
    &:active { transform: scale(0.96) }
  }
}

.status {
  padding: 24px;
  padding-top: 0;
  padding-left: 0;
}

.containerSteps {
  margin: 0;
  padding: 24px 0;
  width: 100%;
  overflow-y: scroll;
  max-height: 300px;
  
  @include breakpoint(lg) {
    height: 550px;
  }
  
  .step {
    padding: 8px 16px;
    margin-bottom: 16px;
    width: 100%;
    height: 64px;
    border-left: solid 6px;
    border-radius: 4px 0 0 4px;
    justify-content: space-between;

    &.READY_FOR_PICKUP {
      border-color: $color-neutral;
    }
    
    &.ACCEPTED {
      border-left: solid 6px $color-primary-1;
    }

    &.ARRIVED_AT_PICKUP {
      border-color: $color-yellow-1;
    }

    &.WAY_TO_DELIVER {
      border-color: $color-blue-1;
    }

    &.CANCELLED_BY_ADMIN, &.CANCELLED_BY_DRIVER, &.CANCELLED_BY_BUSINESS, &.NOT_DELIVERED {
      border-color: $color-error-1;
    }
    
    &.ARRIVED_AT_DELIVERY, &.COMPLETED {
      border-color: $color-green-1;
    }

  }
}

.ant-divider {
  height: 90%;
  border: solid 1px;
}

:global(.ant-steps-item) { padding: 2px 0 0 2px !important; }

.title {
  color: $color-dark;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 16px;
  text-align: left;
  width: 100%;
}

.subtitle { 
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  text-align: left;
  color: $color-purple;
}

.label { 
  font-size: 14px;
  font-weight: 600;
  color: $color-dark;
  width: 100%;
}

.btnMap {
  display: block;
  margin: 20px auto;

  @include breakpoint(md) {
    display: none;
  }

}

.containerMap {
  display: none;

  @include breakpoint(md) {
    display: block;
  }
}

.infoBooking {
  width: 100%;
  margin: 24px 0 0 0;

  .containerInfo {
    height: 60px;
    max-height: 80px;

    li:last-child {
      height: 100%;
      padding: 0 10px;
    }

  }

  .timeInfo {
    border-left: solid 1px $color-neutral-2;
    border-right: solid 1px $color-neutral-2;
  }
  
  .deliverInfo {
    
    .deliveryName {
      text-transform: capitalize;
    }
  }
}

.actions {
  justify-content: center;
  align-items: center;
  
  @include breakpoint(lg) { justify-content: flex-start;}

  .action { 
    width: 165px;
    padding: 8px 16px;
  }

}

.statusFinished {
  color: $color-white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
  padding: 0 25px;
  letter-spacing: -0.6px;
}


.iconPayment {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  .containerIconPayment {
    align-items: center;
    background: $color-neutral-3;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px 16px;
  }
}

.sizeIcon {
  padding: 12px 12px 12px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.noLineDown {
  border-bottom: none !important;
}

.separate {
  margin: 20px 0;
  width: 100%;
  color: $color-neutral-2;
  border-style: dotted;
  border-width: 1px;
}

.ant-list-item-meta {
  align-items: center !important;
}

.ant-list-item-meta-title {
  margin-bottom: 0 !important;
}

.item {
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;

  &.ant-list-item-meta {
    align-items: center !important;
  }

  .number {
    font-weight: 400;
    font-size: 15px;
  }

}

.noteFee {
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
}

.modalMap {
  .containerMap {
    display: block !important;
  }
}

.infoStore {
  margin: 0 !important;
}

.statusStore {
  padding: 8px 16px;
  background: $color-neutral-3;
  border-radius: 6px;

  .title {
    font-weight: 600;
    letter-spacing: .25px;
  }

}

.pickupReferences {
  margin-top: -32px;
  margin-left: 48px;
  color: $color-dark;
  b {
    font-weight: 500;
    margin-right: 3px;
  }
}

.cardInfo {
  background: $color-white;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);

  &.pickup {
    border-bottom: solid 4px $color-primary;
  }
  
  &.delivery {
    border-bottom: solid 4px $color-purple;
  }
}

.distanceBreakUp {
  width: 100%;
  margin: 0 auto;

  @include breakpoint(md) { width: 80%;}

  .billedDistance {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    .avatar {
      display: block;
      margin: 0 auto;
      img { object-fit: contain; }
    }
  }
}

:global(.ant-tooltip-inner) {
  background-color: $color-dark-3;
}
