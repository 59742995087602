@import '@/styles/variables';


.option {
    color: $color-purple;
    font-weight: 500;
    font-size: 14px;

    &.active {
        font-weight: 700;
        border-bottom: 2px solid $color-purple;
    }
}

.selectionContent {
    width: 100%;
    padding: 20px;
    background: $color-neutral-3;

    border: 1px solid $color-neutral;
    border-radius: 4px;
}