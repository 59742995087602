@import "../variables";

.plans {
  &--title {
    color: #00B2E3;
    font-size: 24px;
    line-height: 29px;
    padding-bottom: 20px;
    font-weight: bold;
    position: relative;
    text-align: center;
    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 25%;
      right: 25%;
      border-bottom: 1px solid #D3DFEE;
    }
  }
  &--container {
    padding: 10px;
    @include breakpoint(xxl) {
      padding: 45px;
    }
  }
  &--plan {
    border-radius: 10px;
    border-color: #E1E8F1;
    box-shadow: 0px 0px 10px rgba(225, 225, 225, 0.25);
    max-width: 400px;
    margin: 0 auto;
    color: #3f4d70;
    &--custom {
      margin-top: 11px;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }
    &--item {
      height: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      @include breakpoint(xxl) {
        height: 40px;
      }
    }
    &--item {
      grid-gap: 5px;
      font-weight: 600;
      &--label {
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        display: flex;
        align-items: center;
        gap: 10px;
        svg {
          cursor: pointer;
        }
        @include breakpoint(xxl) {
          display: none;
        }
      }
      &--see-rates {
        font-style: italic;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &--item__label {
      margin-bottom: 20px;
      align-items: center;
      display: flex;
      height: 40px;
      flex-direction: row;
      justify-content: flex-start;
      font-weight: 600;
      font-size: 15px;
      line-height: 16px;
      gap: 10px;
      svg {
        cursor: pointer;
      }
    }
    &--active {
      background-color: #3f4d70;
      color: white

    }
    &--obtained {
      font-weight: bold;
      font-style: italic;
      font-size: 20px;
      color: $color-green;
    }
    &--details {
      color: #00B2E3;
      font-size: 16px;
      font-weight: bold;
      margin-top: 160px;
      margin-bottom: 20px;
    }
    &--title {
      color: #00B2E3;
      font-size: 20px;
      line-height: 20px;
      font-weight: bold;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    &--cost {
      font-size: 26px;
      font-weight: bold;
      text-align: center;
    }
    &--button {
      margin-top: 20px;
      text-align: center;
      height: 40px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bolder;
      button {
        font-weight: 600;
        letter-spacing: .1px;
      }
      @include breakpoint(xl) {
        margin-bottom: 50px;
      }
    }
  }
}
.plan-cost-modal {
  .ant-modal-content {
    border-radius: 5px;
  }
  &--title {
    text-align: center;
    margin-bottom: 10px;
  }
  &--text {
    text-align: center;
  }
}

.plan-rides-cost {
  border: 1px solid #00b2e3;
  border-radius: 8px;
  background-color: #fff;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  &--title{
    width: 100%;
    padding: 15px;
    background-color: rgba(0,178,227,.17);
    color: #00b2e3;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
  }
  &--image{
    margin-top: 20px;
    width: 100px;
  }
  &--prices {
    margin-top: 10px;
    margin-right: auto;
    list-style: none;
    padding-left: 20px;
    li {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 2px;
      svg {
        color: #00b2e3;
      }
    }
  }
  &--button{
    margin-top: 20px;
    text-align: center;
    button {
      width: 200px;
      font-weight: 600;
      letter-spacing: 0.1px;
    }
  }
}
